import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import '../components/RealEstatePage.css';
import { useAuth } from '../context/authContext';

const RealEstatePage = () => {
    const { isLoggedIn } = useAuth();
    const [selectedListing, setSelectedListing] = useState(null);
    const [listings, setListings] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalImageIndex, setModalImageIndex] = useState(0);
    const [showCreateListingModal, setShowCreateListingModal] = useState(false);
    const [isHovered, setIsHovered] = useState(false);

    const [newListing, setNewListing] = useState({
        address: '',
        city: '',
        state: '',
        zip: '',
        price: '',
        beds: '',
        baths: '',
        sqft: '',
        description: '',
        mls: '',
        listingAgent: '',
        images: [],
        imageDescriptions: Array(10).fill(''),
    });

    const onDrop = useCallback((acceptedFiles) => {
        setNewListing((prevState) => ({
            ...prevState,
            images: [...prevState.images, ...acceptedFiles],
        }));
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: 'image/*',
        multiple: true,
    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setNewListing({
            ...newListing,
            [name]: value,
        });
    };

    const handleCreateListing = async () => {
        const formData = new FormData();

        newListing.images.forEach((image) => {
            formData.append('images', image);
        });
        newListing.imageDescriptions.forEach((description, index) => {
            formData.append(`imageDescriptions[${index}]`, description);
        });

        Object.keys(newListing).forEach(key => {
            if (key !== 'images' && key !== 'imageDescriptions') {
                formData.append(key, newListing[key]);
            }
        });

        try {
            const response = await axios.post('http://amywagnerdesigns.com/api/real-estate-listings', formData);
            closeModal();
            setListings([...listings, response.data]);
        } catch (error) {
            console.error("Error adding new listing:", error);
        }
    };

    const handleDeleteImage = (index) => {
        setNewListing((prevState) => ({
            ...prevState,
            images: prevState.images.filter((_, i) => i !== index),
        }));
    };

    const openImageModal = (index) => {
        setModalImageIndex(index);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setShowCreateListingModal(false);
    };

    const openCreateListingModal = () => {
        setShowCreateListingModal(true);
    };

    const handleNextImage = () => {
        if (selectedListing) {
            setModalImageIndex((prevIndex) =>
                (prevIndex + 1) % selectedListing.images.length
            );
        }
    };

    const handlePrevImage = () => {
        if (selectedListing) {
            setModalImageIndex((prevIndex) =>
                (prevIndex - 1 + selectedListing.images.length) %
                selectedListing.images.length
            );
        }
    };

    useEffect(() => {
        axios.get('http://amywagnerdesigns.com/api/real-estate-listings')
            .then(response => {
                const updatedListings = response.data.map(listing => {
                    listing.images = listing.images.map(image => `http://amywagnerdesigns.com/uploads/${image}`);
                    return listing;
                });

                setListings(updatedListings);
                if (updatedListings.length > 0) {
                    setSelectedListing(updatedListings[0]);
                }
            })
            .catch(error => {
                console.error("Error fetching listings:", error);
            });
    }, []);

    const ListingThumbnail = ({ listing, onSelect, isSelected }) => {
        const imageUrl = listing.images?.[0] || 'http://amywagnerdesigns.com/uploads/Gant01.jpeg';
        return (
            <div className={`listing-thumbnail ${isSelected ? 'selected' : ''}`} onClick={() => onSelect(listing)}>
                <img src={imageUrl} alt="" />
                <div className="thumbnail-info">
                    <h3>${listing.price}</h3>
                    <p>
                        {listing.address}<br />
                        {listing.city}, {listing.state} {listing.zip}
                    </p>
                </div>
            </div>
        );
    };

    const handleDeleteListing = async () => {
        try {
            if (!selectedListing) return;

            await axios.delete(`http://amywagnerdesigns.com/api/real-estate-listings/${selectedListing.id}`);
            const updatedListings = listings.filter(listing => listing.id !== selectedListing.id);
            setListings(updatedListings);

            if (updatedListings.length === 0) {
                setSelectedListing(null);
            } else {
                setSelectedListing(updatedListings[0]);
            }
        } catch (error) {
            console.error("Error deleting listing:", error);
        }
    };

    const handleMouseOver = () => {
        setIsHovered(true);
    };

    const handleMouseOut = () => {
        setIsHovered(false);
    };

    return (
        <div>
            <div className="real-estate-page">
                {isLoggedIn && (
                    <button onClick={openCreateListingModal}>Create Listing</button>
                )}
                <div className="real-estate-banner">Real Estate Listings</div>
                {selectedListing && (
                    <div className="selected-listing">
                        <div className="listing-info-row">
                            <div className="listing-info-item">
                                <strong>Price:</strong> ${selectedListing.price}
                            </div>
                            <div className="listing-info-item">
                                <strong>Address:</strong> {selectedListing.address}, {selectedListing.city}, {selectedListing.state} {selectedListing.zip}
                            </div>
                            <div className="listing-info-item">
                                <strong>Beds:</strong> {selectedListing.beds}, <strong>Baths:</strong> {selectedListing.baths}
                            </div>
                            <div className="listing-info-item">
                                <strong>Sq Ft:</strong> {selectedListing.sqft}
                            </div>
                        </div>
                        <div className="listing-images">
                            {selectedListing.images && selectedListing.images.length > 0 && (
                                <div>
                                    <img
                                        src={selectedListing.images[0]}
                                        alt="Featured"
                                        className="featured-image"
                                        onClick={() => openImageModal(0)}
                                    />
                                    <div className="thumbnail-gallery">
                                        {selectedListing.images.slice(1).map((image, index) => (
                                            <img
                                                key={index + 1}
                                                src={image}
                                                alt=""
                                                className="thumbnail-image"
                                                onClick={() => openImageModal(index + 1)}
                                            />
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="listing-details">
                            <h4>Description: {selectedListing.description}</h4>
                            <Link to="/contact#contact-form" className="request-showing-btn">
                                Request a showing!
                            </Link>
                            {isLoggedIn && (
                                <button className="delete-listing-btn" onClick={handleDeleteListing}>
                                    Delete Listing
                                </button>
                            )}
                        </div>
                    </div>
                )}
                <div className="listing-thumbnails">
                    {listings.filter(listing => listing !== selectedListing).map((listing) => (
                        <ListingThumbnail
                            key={listing.id}
                            listing={listing}
                            isSelected={listing === selectedListing}
                            onSelect={setSelectedListing}
                        />
                    ))}
                </div>

                {/* Image Modal */}
                {isModalOpen && selectedListing && (
                    <div className="modal-overlay" onClick={closeModal}>
                        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                            <img
                                src={selectedListing.images[modalImageIndex]}
                                alt="Large view"
                            />
                            <div className="modal-controls">
                                <button onClick={handlePrevImage}>Previous</button>
                                <button onClick={handleNextImage}>Next</button>
                            </div>
                        </div>
                    </div>
                )}

                {/* Create Listing Modal */}
                {showCreateListingModal && (
                    <div className="modal-overlay" onClick={closeModal}>
                        <div className="modal-content create-listing-modal" onClick={(e) => e.stopPropagation()}>
                            <h2>Create New Listing</h2>
                            <input
                                type="text"
                                name="address"
                                placeholder="Address"
                                value={newListing.address}
                                onChange={handleInputChange}
                            />
                            <input
                                type="text"
                                name="city"
                                placeholder="City"
                                value={newListing.city}
                                onChange={handleInputChange}
                            />
                            <input
                                type="text"
                                name="state"
                                placeholder="State"
                                value={newListing.state}
                                onChange={handleInputChange}
                            />
                            <input
                                type="text"
                                name="zip"
                                placeholder="Zip Code"
                                value={newListing.zip}
                                onChange={handleInputChange}
                            />
                            <input
                                type="text"
                                name="price"
                                placeholder="Price"
                                value={newListing.price}
                                onChange={handleInputChange}
                            />
                            <input
                                type="text"
                                name="beds"
                                placeholder="Beds"
                                value={newListing.beds}
                                onChange={handleInputChange}
                            />
                            <input
                                type="text"
                                name="baths"
                                placeholder="Baths"
                                value={newListing.baths}
                                onChange={handleInputChange}
                            />
                            <input
                                type="text"
                                name="sqft"
                                placeholder="Square Feet"
                                value={newListing.sqft}
                                onChange={handleInputChange}
                            />
                            <textarea
                                name="description"
                                placeholder="Description"
                                value={newListing.description}
                                onChange={handleInputChange}
                            />

                            {/* Drag-and-Drop Image Upload */}
                            <div {...getRootProps()} className="dropzone">
                                <input {...getInputProps()} />
                                {isDragActive ? (
                                    <p>Drop the images here...</p>
                                ) : (
                                    <p>Drag 'n' drop some images here, or click to select images</p>
                                )}
                            </div>

                            {/* Display uploaded images with delete option */}
                            <div className="preview-images">
                                {newListing.images.map((file, index) => (
                                    <div key={index} className="preview-image-wrapper">
                                        <img src={URL.createObjectURL(file)} alt={`Preview ${index}`} />
                                        <button
                                            type="button"
                                            onClick={() => handleDeleteImage(index)}
                                            className="delete-image-button"
                                        >
                                            Delete
                                        </button>
                                    </div>
                                ))}
                            </div>

                            {/* Create Listing Button */}
                            <button onClick={handleCreateListing} className="create-listing-btn">Create Listing</button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default RealEstatePage;










