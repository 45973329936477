import React from 'react';
import '../Nav.css';
import logo from '../assets/RR_logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom';
import { useAuth } from '../context/authContext';


function GlobalNavbar() {

  const { isLoggedIn, logout } = useAuth();

  const handleLogout = () => {
    logout();
  };

  return (
    <div>
      <div className="teal-navbar">
        <div className="teal-navbar-left">
          <a href="tel:502-384-3660" className="maroon-link">
            <FontAwesomeIcon icon={faPhone} /> 502-384-3660
          </a>
          <a href="mailto:info.reflectionsofyou@gmail.com" className="maroon-link">
            <FontAwesomeIcon icon={faEnvelope} /> info.reflectionsofyou@gmail.com
          </a>
          <a href="https://www.facebook.com/reflctionsofyoubyamy?mibextid=ZbWKwL " className="social-link">
            <FontAwesomeIcon icon={faFacebook} />
          </a>
          <span className="social-link-space"></span>
          <a href="https://twitter.com/amywagnerdesign" className="social-link">
            <FontAwesomeIcon icon={faTwitter} />
          </a>
          <span className="social-link-space"></span>
          <a href="https://instagram.com/amywagnerdesigns?igshid=MzRlODBiNWFlZA==" className="social-link">
            <FontAwesomeIcon icon={faInstagram} />
          </a>
        </div>
        {isLoggedIn ? (
          <button onClick={handleLogout}>Logout</button>
        ) : (
          <Link to="/login">My Account</Link>
        )}
      </div>

      <div className="cream-navbar">
        <div className="cream-navbar-left">
          <img src={logo} alt="Logo" className="navbar-logo" />
        </div>
        <div className="cream-navbar-right">
        <Link to="/">
          <button className="cream-navbar-link big-link">Home</button>
        </Link>
          <div className="dropdown">
            <button className="cream-navbar-link big-link dropdown-toggle">Our Services</button>
            <div className="dropdown-menu">
              <Link to="/realEstateServices">
                <a href="#realEstateServices" className="dropdown-item">Real Estate Services</a>
              </Link>
              <Link to="/interiordesign">
                <a href="#interiorDesign" className="dropdown-item">Interior Design</a>
              </Link>
              {/* <Link to="/homeremodeling">
                <a href="#homeRemodeling" className="dropdown-item">Home Remodeling</a>
              </Link> */}
            </div>
          </div>
          {/* <div className="dropdown">
            <button className="cream-navbar-link big-link dropdown-toggle">Gallery</button>
            <div className="dropdown-menu">
            <Link to="/gallery">
              <a href="#galleryImages" className="dropdown-item">Gallery</a>
            </Link>
            <Link to="/videotours">
              <a href="#videoTours" className="dropdown-item">Video Tours</a>
            </Link>
            </div>
          </div> */}
          <Link to="/aboutus">
          <button className="cream-navbar-link big-link">About Us</button>
          </Link>
          <Link to="/contact">
            <button className="cream-navbar-link big-link">Contact Us</button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default GlobalNavbar;










