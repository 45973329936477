import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Link } from 'react-router-dom';
import '../components/fade-in.css';
import '../components/HomePage.css';  // Import the CSS file
import { useAuth } from '../context/authContext';
import axios from 'axios';

const HomePage = () => {
  const { isLoggedIn, logout } = useAuth();
  const [isLoaded] = useState(true);
  const [backgroundImage, setBackgroundImage] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem('token');

    if (!token) {
      console.error('No token found in localStorage');
    }

    fetchBackgroundImage();
  }, [isLoggedIn]);

  const handleUpload = useCallback(() => {
    axios.get('http://amywagnerdesigns.com/api/background-image/')
      .then((response) => {
        const imageUrl = `/uploads/${response.data.image_path}`;
        setBackgroundImage(imageUrl);
      })
      .catch((error) => {
        console.log(error);
      });

    if (selectedImage) {
      const formData = new FormData();
      formData.append('image', selectedImage);
      axios
        .post('http://amywagnerdesigns.com/uploadReplaceBackgroundImage', formData)
        .then(() => {
          fetchBackgroundImage();
        })
        .catch((error) => {
          console.error('Error uploading image:', error);
        });
    }
  }, [selectedImage]);

  useEffect(() => {
    if (selectedImage) {
      handleUpload();
    }
  }, [selectedImage, handleUpload]);

  const fetchBackgroundImage = () => {
    axios
      .get('http://amywagnerdesigns.com/api/background-image')
      .then((response) => {
        if (response.data && response.data.image_path) {
          const basePath = 'http://amywagnerdesigns.com/uploads';
          const imageUrl = `${basePath}/${response.data.image_path}`;
          setBackgroundImage(imageUrl);
        } else {
          console.error('Invalid response format:', response.data);
        }
      })
      .catch((error) => {
        console.error('Error fetching background image:', error);
      });
  };

  const fileInputRef = useRef(null);

  const handleImageUpload = () => {
    fileInputRef.current.click();
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    setSelectedImage(file);
  };

  const handleLogout = () => {
    logout();
  };

  return (
    <div>
      {isLoggedIn && (
        <div>
          <input
            type="file"
            accept="image/*"
            onChange={handleFileInputChange}
            ref={fileInputRef}
            style={{ display: 'none' }}
          />
          <button onClick={handleImageUpload}>Upload Photo</button>
        </div>
      )}

      <div
        className="homepage-container"
        style={{
          backgroundImage: `url(${backgroundImage})`,
        }}
      >
        <div className={`fade-in-container ${isLoaded ? '' : 'fade-out'}`}>
          <h1 className="homepage-title">
            Reflections of You, By Amy
          </h1>
          <p className="homepage-subtitle">
            Making All of Your Dreams in Design Come True!
          </p>
        </div>
      </div>

      <div className="schedule-section">
        <p>Schedule Your Initial Consult Today!</p>
        <Link to="/contact" style={{ textDecoration: 'none', width: '100%' }}>
          <button
            className="schedule-button"
          >
            Schedule Today!
          </button>
        </Link>
      </div>
    </div>
  );
};

export default HomePage;






